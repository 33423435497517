<template>
  <div>
    <el-table
      :data="pocs"
      stripe
      border
      size="mini"
      style="margin-top: 10px; float:left;"
    >
      <el-table-column
        prop="name"
        label="Name"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column prop="version" label="Version"></el-table-column>
      <el-table-column label="URL" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-link :href="scope.row.url" target="_blank">{{
            scope.row.url
          }}</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="Status" sortable>
        <template slot-scope="scope">
          <el-tag
            size="small"
            type="success"
            v-if="scope.row.status == 'running'"
            >{{ scope.row.status }}</el-tag
          >
          <el-tag
            type="danger"
            size="small"
            v-else-if="scope.row.status == 'stopped'"
            >{{ scope.row.status }}</el-tag
          >
          <el-tag
            type="info"
            size="small"
            v-else-if="scope.row.status == 'terminated'"
            >{{ scope.row.status }}</el-tag
          >
          <el-tag size="small" v-else>{{ scope.row.status }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Validity" align="center">
        <el-table-column
          prop="validFrom"
          label="Valid From"
          show-overflow-tooltip
          :formatter="dateFormatterFrom"
        >
        </el-table-column>
        <el-table-column
          :formatter="dateFormatterTo"
          prop="validTo"
          label="Valid To"
          show-overflow-tooltip
        ></el-table-column>
      </el-table-column>
      <el-table-column
        prop="creationTime"
        show-overflow-tooltip
        sortable
        label="Created On"
        :formatter="dateFormatterCreate"
      >
        <!-- <template slot-scope="scope">
          {{
            new Date(scope.row.creationTime).toLocaleString("chinese", {
              hour12: false,
            })
          }}
        </template> -->
      </el-table-column>
      <el-table-column
        prop="assignee"
        label="Owner"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="customer"
        label="Customer"
        show-overflow-tooltip
      ></el-table-column>
    </el-table>
  </div>
</template>
<script>
import http from "../utils/http";
export default {
  name: "ActivePoc",
  data() {
    return {
      pocs: [],
    };
  },
  mounted() {
    this.getPoc();
  },
  methods: {
    dateFormatterCreate(row) {
      var y, m, d;
      if (row.creationTime != "" && row.creationTime != null) {
        var f = new Date(row.creationTime);
        y = f.getFullYear();
        m = f.getMonth() + 1;
        d = f.getDate();
        return (
          y +
          "-" +
          (m < 10 ? "0" + m : m) +
          "-" +
          (d < 10 ? "0" + d : d) +
          ", " +
          f.getHours() +
          ":" +
          f.getMinutes() +
          ":" +
          f.getSeconds()
        );
      } else {
        return "N.A.";
      }
    },
    dateFormatterTo(row) {
      var y, m, d;
      if (row.validTo != "" && row.validTo != null) {
        var f = new Date(row.validTo);
        y = f.getFullYear();
        m = f.getMonth() + 1;
        d = f.getDate();
        return y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d);
      } else {
        return "N.A.";
      }
    },
    dateFormatterFrom(row) {
      var y, m, d;
      if (row.validFrom != "" && row.validFrom != null) {
        var f = new Date(row.validFrom);
        y = f.getFullYear();
        m = f.getMonth() + 1;
        d = f.getDate();
        return y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d);
      } else {
        return "N.A.";
      }
    },
    getPoc() {
      http.get("/poc").then((res) => {
        if (res.data.status == 1) {
          this.pocs.splice(0, this.pocs.length);
          this.pocs = res.data.data;
          this.pocs = this.pocs.filter((value) => {
            return (
              value.status == "not initialized" ||
              value.status == "stopped" ||
              value.status == "running"
            );
          });
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
  },
};
</script>
<style scoped></style>
