<template>
  <div>
    <el-table
      :data="requests"
      stripe
      border
      size="mini"
      :row-class-name="tableRowClassName"
    >
      <el-table-column label="ID" prop="id" sortable width="130px">
      </el-table-column>
      <el-table-column label="Title" prop="title" :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column
        label="Description"
        prop="description"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        sortable
        :show-overflow-tooltip="true"
        label="Status"
        prop="status"
      >
      </el-table-column>
      <el-table-column
        sortable
        :show-overflow-tooltip="true"
        label="Created"
        prop="creationTime"
      ></el-table-column>
      <!-- <el-table-column
        label="Creator"
        prop="creator.username"
      ></el-table-column> -->
      <el-table-column label="Customer" prop="customer"></el-table-column>
      <!-- <el-table-column
        label="Owner"
        prop="assignee"
        :show-overflow-tooltip="true"
      ></el-table-column> -->
      <el-table-column
        label="Type"
        prop="type"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column label="Priority" prop="priority"></el-table-column>
    </el-table>
  </div>
</template>
<script>
import http from "../utils/http";
export default {
  name: "OpenRequest",
  data() {
    return {
      requests: [],
    };
  },
  mounted() {
    this.query();
  },
  methods: {
    tableRowClassName({ row }) {
      if (row.status === "New") {
        return "open-row";
      } else if (row.status === "Closed") {
        return "closed-row";
      }
      return "";
    },
    query() {
      http.post("/request/myquery").then((res) => {
        if (res.data.status === 1) {
          this.requests = res.data.data;
          this.requests = this.requests.filter((value) => {
            return (
              value.status == "New" ||
              value.status == "In Progress" ||
              value.status == "Pending" ||
              value.status == "Reopened"
            );
          });
          this.requests.forEach((request) => {
            if (
              request.creationTime &&
              typeof request.creationTime != "undefined"
            ) {
              request.creationTime = new Date(
                request.creationTime
              ).toLocaleString("chinese", { hour12: false });
            }

            var fileList = [];
            if (typeof request.attachments != "undefined") {
              request.attachments.forEach((a) => {
                fileList.push({ name: a });
              });
              request.fileList = fileList;
            }
          });
        }
      });
    },
  },
};
</script>
<style scoped></style>
<style>
.el-table .closed-row {
  /* background: rgb(216, 216, 213); */
  color: rgb(194, 192, 192);
}

.el-table .open-row {
  color: rosybrown;
}

.el-table .success-row {
  background: #def1d5;
}
</style>
