<template>
  <div>
    <div class="row">Active POCs</div>
    <active-poc></active-poc>
    <div class="row" style="margin-top: 20px;">My Open Requests</div>
    <open-request></open-request>
    <div class="row" style="margin-top: 20px;">Latest Releases</div>
    <el-descriptions style="float:left;" title="7.8.1.0" :column="1">
      <el-descriptions-item label="Linux"
        ><el-link
          type="primary"
          target="_blank"
          href="https://www.methodpark.de/downloads/stages/stages-7.8.1.0-1.x86_64.rpm"
          >https://www.methodpark.de/downloads/stages/stages-7.8.1.0-1.x86_64.rpm</el-link
        ></el-descriptions-item
      >
      <el-descriptions-item label="Windows"
        ><el-link
          type="primary"
          target="_blank"
          href="https://www.methodpark.de/downloads/stages/stages-7.8.1.0-x64.exe"
          >https://www.methodpark.de/downloads/stages/stages-7.8.1.0-x64.exe</el-link
        ></el-descriptions-item
      >
      <el-descriptions-item label="Release Note"
        ><el-link
          type="primary"
          target="_blank"
          href="https://www.methodpark.de/downloads/stages/changes-7.8.1.0.html"
          >https://www.methodpark.de/downloads/stages/changes-7.8.1.0.html</el-link
        ></el-descriptions-item
      >
      <el-descriptions-item label="Stages User Guide">
        <el-link
          type="primary"
          target="_blank"
          href="https://doc.stagesasaservice.com/doku.php/start"
          >https://doc.stagesasaservice.com/doku.php/start</el-link
        >
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>
<script>
import http from "../utils/http";
import ActivePoc from "../components/ActivePoc.vue";
import OpenRequest from "../components/OpenRequest";
export default {
  name: "Home",
  components: {
    ActivePoc,
    OpenRequest,
  },
  data() {
    return {
      height: window.innerHeight - 170,
      requests: [],
      pocs: [],
    };
  },
  mounted() {
    this.getPoc();
  },
  methods: {
    getPoc() {
      http.get("/poc").then((res) => {
        if (res.data.status == 1) {
          this.pocs.splice(0, this.pocs.length);
          this.pocs = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
  },
};
</script>
<style scoped>
.row {
  width: 100%;
  float: left;
  text-align: left;
  height: 40px;
  line-height: 40px;
  font-weight: bold;
}
</style>
